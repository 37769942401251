import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import "dayjs/locale/ru";
import { useSelector } from "react-redux";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { summaryService } from "../api";
import { getReferencesFilter, getThread } from "../redux/selectors";

const TonalStatistic: React.FC = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const thread = useSelector(getThread);
  const referencesFilter = useSelector(getReferencesFilter);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [open, setOpen] = useState(false);
  const [keys, setKeys] = useState({
    positive: 0,
    netural: 0,
    negative: 0,
    total: 0,
  });
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("pub");

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChangeType = (value: string) => {
    setType(value);
  };

  const selectWords = () => {
    return type == "pub"
      ? ["публикация", "публикации", "публикаций"]
      : ["комментарий", "комментария", "комментариев"];
  };

  const num_word = (value: any, words: any) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
  };

  const calcPr = (num: number) => {
    return keys.total > 0 ? ((num * 100) / keys.total).toFixed(1) : 0;
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  useEffect(() => {
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        referenceFilter: referencesFilter
      };
      setLoading(true);
      type == "pub"
        ? summaryService
            .loadTrustDailyPosts(params)
            .then((resp) => {
              let data: any = {};
              for (let key in resp.total) {
                let result = resp.total[key].reduce(function (
                  sum: any,
                  current: any
                ) {
                  return sum + current[1];
                },
                0);
                data[key] = result;
              }
              setKeys(data);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
            })
        : summaryService
            .loadTrustDailyComments(params)
            .then((resp) => {
              let data: any = {};
              for (let key in resp.total) {
                let result = resp.total[key].reduce(function (
                  sum: any,
                  current: any
                ) {
                  return sum + current[1];
                },
                0);
                data[key] = result;
              }
              setKeys(data);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });
    }
  }, [thread, dateString, type]);

  return (
    <div className="white-block">
      <div className="flex-space">
        <div>
          <p className="block-title">
            Статистика тональности{" "}
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <p className="red light">
            Рекомендуется публиковать больше позитивного контента для аудитории
          </p>
        </div>
        <div className="flex">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonSolidCheckedColor: "#7856FE",
                  buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                  colorPrimary: "rgba(0, 0, 0, 0.1)",
                  buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                },
              },
            }}
          >
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
              //disabled={isLoading}
            />
          </ConfigProvider>

          {value == "period" && (
            <RangePicker
              format={{
                format: "DD.MM.YYYY"
              }}
              onChange={onPeriodChange}
              style={{ width: "278px" }}
              className="choose-period"
              allowClear={false}
              locale={locale}
            />
          )}

          <ConfigProvider
            theme={{
              components: {
                Select: {
                  activeBorderColor: "#7856FE",
                },
              },
            }}
          >
            <Select
              value={type}
              onChange={handleChangeType}
              style={{ width: 150 }}
              allowClear={false}
              options={[
                { value: "pub", label: "Публикации" },
                { value: "com", label: "Комментарии" },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <div className="grid3">
          <div className="grid-block green-bg">
            <div
              className={
                window.location.pathname.includes("thread") ? "flex-end" : ""
              }
            >
              <div>
                <p className="block-title">Позитив</p>
                <p className="grey">
                  {keys.positive.toLocaleString()}{" "}
                  {num_word(keys.positive, selectWords())}
                </p>
              </div>
              <div className="green title-big"> {calcPr(keys.positive)} %</div>
            </div>
          </div>
          <div className="grid-block gray-block">
            <div
              className={
                window.location.pathname.includes("thread") ? "flex-end" : ""
              }
            >
              <div>
                <p className="block-title">Нейтрально</p>
                <p className="grey">
                  {keys.netural.toLocaleString()}{" "}
                  {num_word(keys.netural, selectWords())}
                </p>
              </div>
              <div className="title-big"> {calcPr(keys.netural)} %</div>
            </div>
          </div>
          <div className="grid-block red-bg">
            <div
              className={
                window.location.pathname.includes("thread") ? "flex-end" : ""
              }
            >
              <div>
                <p className="block-title">Негатив</p>
                <p className="grey">
                  {keys.negative.toLocaleString()}{" "}
                  {num_word(keys.negative, selectWords())}
                </p>
              </div>
              <div className="red title-big"> {calcPr(keys.negative)} %</div>
            </div>
          </div>
        </div>
      )}
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График количества упоминаний иллюстрирует количество упоминаний вашего
          бренда и конкурентов в различных социальных сетях за выбранный период
          времени
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Цветовая кодировка: Разные цвета на графике обозначают ваш бренд и
            конкурентов. Это позволяет легко сравнивать уровень упоминаний.
          </li>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики упоминаний.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default TonalStatistic;
