import React from "react";

const IconMonitoring: React.FC = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.120117 15.3599C0.120117 7.34805 6.35154 0.791652 14.2321 0.272949V8.37534C10.8127 8.86445 8.18412 11.8052 8.18412 15.3599C8.18412 19.2568 11.3432 22.4159 15.2401 22.4159C16.825 22.4159 18.2879 21.8933 19.4659 21.0111L25.1952 26.7404C22.5356 29.0688 19.0526 30.4799 15.2401 30.4799C6.88957 30.4799 0.120117 23.7104 0.120117 15.3599Z"
        fill="#7856FE"
      />
      <path
        opacity="0.6"
        d="M26.6207 25.3149C28.949 22.6553 30.3601 19.1724 30.3601 15.3599C30.3601 13.2763 29.9387 11.2912 29.1764 9.48505L21.8026 12.7623C22.1211 13.5662 22.2961 14.4426 22.2961 15.3599C22.2961 16.9448 21.7736 18.4076 20.8914 19.5855L26.6207 25.3149Z"
        fill="#7856FE"
      />
      <path
        opacity="0.3"
        d="M16.248 8.37534V0.272949C21.3719 0.610202 25.7985 3.49983 28.2686 7.68236L20.7923 11.0051C19.6967 9.61017 18.0867 8.63834 16.248 8.37534Z"
        fill="#7856FE"
      />
    </svg>
  );
};

export default IconMonitoring;
