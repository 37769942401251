import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loginAuth } from "../redux/actions/auth.actions";
import { Button, Input } from "antd";
import "./Login-styles.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store/hooks";
import {
  getAppIsInitialize,
  getAuthErrorMsg,
  getAuthIsLoading,
  getAuthIsLoggedIn,
} from "../redux/selectors";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getAuthIsLoggedIn);
  const isInitialize = useSelector(getAppIsInitialize);
  const isLoading = useSelector(getAuthIsLoading);
  const errorMsg = useSelector(getAuthErrorMsg);
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    login: "",
    password: "",
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/main");
    }
  }, [isLoggedIn]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    dispatch(loginAuth({
      login: state.login,
      password: state.password
    }))

    if (isLoggedIn) {
      navigate("/main");
    }
  }


  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  return (
    <div className="login">
      <div className="login__wrap">
        <div className="login__head">
          {isLoading ? "Выполняем вход..." : "Вход в систему"}
        </div>
        <div className="login__body">
          <form
            data-testid="form"
            className="login__form"
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              placeholder="Логин"
              name="login"
              onChange={handleInput}
            />
            <Input
              type="password"
              placeholder="Пароль"
              name="password"
              onChange={handleInput}
            />
            <div className="login__row">
              <Button
                data-testid="submit"
                type="primary"
                size="large"
                htmlType="submit"
              >
                Войти
              </Button>
              {errorMsg !== "" && (
                <div className="login__error">
                  Неправильный логин или пароль
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
