import {
  Button,
  ConfigProvider,
  DatePicker,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  changePostPage,
  feedFilterClear,
  feedFilterSubmit,
} from "../redux/actions";
import { getReferencesFilter, getThread } from "../redux/selectors";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { Link } from "react-router-dom";
import IconFileExcel from "../components/icons/fileExcel";
import { CloseOutlined } from "@ant-design/icons";
import IconFileWord from "../components/icons/fileWord";
import IconFileAdd from "../components/icons/fileAdd";

const Reports = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const [tonality, setTonality] = useState(feedFilter.tonal);
  const [repostOption, setRepostOption] = useState(feedFilter.repostoption);
  const [friendly, setFriendly] = useState(feedFilter.friendly);
  const [sorting, setSorting] = useState(feedFilter.sort);
  const [switchValue, setSwitchValue] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportFileType, setReportFileType] = useState("docx");
  const [typeReport, setTypeReport] = useState("day");
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const referencesFilter = useSelector(getReferencesFilter);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [source, setSource] = useState({
    smi: feedFilter.smi,
    vk: feedFilter.vk,
    tw: feedFilter.tw,
    ig: feedFilter.ig,
    tg: feedFilter.tg,
    yt: feedFilter.yt,
    ok: feedFilter.ok,
    fb: feedFilter.fb,
  });
  const [sourceOptions, setSourceOptions] = useState([
    "tg",
    "vk",
    "ok",
    "fb",
    "tw",
    "yt",
    "ig",
    "smi",
  ]);
  const thread = useSelector(getThread);
  const dispatch = useAppDispatch();
  const optionsType = [
    { value: "day", label: "Дням" },
    { value: "week", label: "Неделям" },
    { value: "month", label: "Месяцам" },
    { value: "quart", label: "Кварталам" },
  ];

  const optionsFormat = [
    { value: "docx", label: "Docs" },
    { value: "pdf", label: "Pdf" },
  ];

  let order = "desc";
  let type = "date";
  if (sorting == "reitAsc") {
    type = "rating";
    order = "asc";
  }
  if (sorting == "reitDesc") {
    type = "rating";
    order = "desc";
  }
  if (sorting == "dateAsc") {
    type = "date";
    order = "asc";
  }
  if (sorting == "dateDesc") {
    type = "date";
    order = "desc";
  }
  if (sorting == "likeAsc") {
    type = "likes";
    order = "asc";
  }
  if (sorting == "likeDesc") {
    type = "likes";
    order = "desc";
  }
  if (sorting == "repostAsc") {
    type = "reposts";
    order = "asc";
  }
  if (sorting == "repostDesc") {
    type = "reposts";
    order = "desc";
  }
  if (sorting == "viewAsc") {
    type = "viewed";
    order = "asc";
  }
  if (sorting == "viewDesc") {
    type = "viewed";
    order = "desc";
  }
  if (sorting == "comAsc") {
    type = "commentaries";
    order = "asc";
  }
  if (sorting == "comDesc") {
    type = "commentaries";
    order = "desc";
  }
  if (sorting == "") {
    type = "date";
    order = "desc";
  }

  let sourceNums = sourceOptions.map((i) => {
    switch (i) {
      case "vk":
        return 1;
        break;
      case "dz":
        return 11;
        break;
      case "ok":
        return 10;
        break;
      case "smi":
        return 4;
        break;
      case "tg":
        return 5;
        break;
      case "ig":
        return 7;
        break;
      case "tw":
        return 2;
        break;
      case "fb":
        return 3;
        break;
      case "yt":
        return 8;
        break;
      default:
        return 0;
    }
  });

  let fullFilterString = `thread_id=${thread?.id}&from=${
    dateString[0].slice(0, 10) + " 00:00:00"
  }&to=${
    dateString[1].slice(0, 10) + " 23:59:59"
  }&sort[type]=${type}&sort[order]=${order}&filter[trustoption]=${
    feedFilter.tonal
  }&filter[repostoption]=${feedFilter.repostoption}${sourceNums
    .map((item, id) => `&filter[network_id][${id}]=${item}`)
    .join("")}`;

  let shortFilterString = `thread_id=${thread?.id}&from=${
    dateString[0].slice(0, 10) + " 00:00:00"
  }&to=${dateString[1].slice(0, 10) + " 23:59:59"}`;

  let createdString = `thread_id=${thread?.id}&date_from=${
    dateString[0].slice(0, 10) + " 00:00:00"
  }&date_to=${
    dateString[1].slice(0, 10) + " 23:59:59"
  }&type=${typeReport}&format=${reportFileType}`;

  let morningReport = `https://api.glassen-it.com/component/socparser/content/posts_excell_light?${fullFilterString}`;
  let postListReport = `https://api.glassen-it.com/component/socparser/content/posts_excell?${fullFilterString}`;
  let topSourcesReport = `https://api.glassen-it.com/component/socparser/stats/owners_rating?${shortFilterString}`;
  let commentsReport = `https://api.glassen-it.com/component/socparser/content/comments_excell?${shortFilterString}`;
  let dailyReport = `https://api.glassen-it.com/component/socparser/content/getReportAnalytic?${shortFilterString}`;
  let weeklyReport = `https://api.glassen-it.com/component/socparser/content/getReportMonitoring?${shortFilterString}`;
  let weekReport = `https://api.glassen-it.com/component/socparser/content/getReportDocxRef?period=week&thread_id=${thread?.id}&reference_ids[]=${referencesFilter}`;
  let dayReport = `https://api.glassen-it.com/component/socparser/content/getReportDocxRef?period=day&thread_id=${thread?.id}&reference_ids[]=${referencesFilter}`;
  let monthReport = `https://api.glassen-it.com/component/socparser/content/getReportDocxRef?period=month&thread_id=${thread?.id}&reference_ids[]=${referencesFilter}`;
  let periodReport = `https://api.glassen-it.com/component/socparser/content/getReportDocxRef?period=month&thread_id=${
    thread?.id
  }&reference_ids[]=${referencesFilter}&date_from=${
    dateString[0].slice(0, 10) + " 00:00:00"
  }&date_to=${dateString[1].slice(0, 10) + " 23:59:59"}`;

  const okModal = () => {
    setCreateModalOpen(false);
    let basicLink = `https://api.glassen-it.com/component/socparser/content/getReportDocx?${createdString}`;
    setReportModalOpen(true);
    var link = document.createElement("a");
    link.setAttribute("href", basicLink);
    link.setAttribute("download", "pdfname");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    onClear();
  }, []);

  useEffect(() => {
    dispatch(
      feedFilterSubmit({
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        smi: source.smi,
        vk: source.vk,
        tw: source.tw,
        fb: source.fb,
        ig: source.ig,
        tg: source.tg,
        yt: source.yt,
        ok: source.ok,
        tonal: tonality,
        friendly: friendly,
        sorting: sorting,
        profile_id: [],
        repostoption: repostOption,
      })
    );
  }, [source, dateString, sorting, tonality, thread, repostOption]);

  const onClear = (): void => {
    setDateString([
      new Date().toJSON().split("T")[0],
      new Date().toJSON().split("T")[0],
    ]);
    setSource({
      smi: true,
      vk: true,
      tw: true,
      fb: true,
      ig: true,
      tg: true,
      yt: true,
      ok: true,
    });
    setValue("today");
    setSourceOptions(["tg", "vk", "ok", "fb", "tw", "yt", "ig", "smi"]);
    setTonality("");
    setFriendly("");
    setSorting(null);
    setRepostOption("whatever");
    setSwitchValue(false);
    dispatch(feedFilterClear());
    dispatch(changePostPage(1));
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
    dispatch(changePostPage(1));
  };

  const handleChangeSort = (value: string) => {
    setSorting(value);
    dispatch(changePostPage(1));
  };

  const handleChangeTonal = (value: string) => {
    setTonality(value);
    dispatch(changePostPage(1));
  };

  const handleChangeSource = (value: string[]) => {
    for (let key in source) {
      value.indexOf(key) != -1
        ? setSource((prevState) => ({
            ...prevState,
            [key]: true,
          }))
        : setSource((prevState) => ({
            ...prevState,
            [key]: false,
          }));
    }
    setSourceOptions(value);
    dispatch(changePostPage(1));
  };

  return (
    <div className="App">
      <h1>Отчеты</h1>
      <div className="white-block">
        {/*        <div className="flex-space">
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    activeBorderColor: "#7856FE",
                  },
                },
              }}
            >
              <Select
                style={{ width: 195 }}
                allowClear={false}
                onChange={handleChangeSort}
                options={[
                  { value: "dateAsc", label: "Сначала старые" },
                  { value: "dateDesc", label: "Сначала новые" },
                  { value: "viewDesc", label: "Больше просмотров" },
                  { value: "viewAsc", label: "Меньше просмотров" },
                  { value: "comDesc", label: "Больше комментариев" },
                  { value: "comAsc", label: "Меньше комментариев" },
                  { value: "repostDesc", label: "Больше репостов" },
                  { value: "repostAsc", label: "Меньше репостов" },
                  { value: "likeDesc", label: "Больше лайков" },
                  { value: "likeAsc", label: "Меньше лайков" },
                ]}
                placeholder="Сортировать по"
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    activeBorderColor: "#7856FE",
                  },
                },
              }}
            >
              <Select
                value={sourceOptions}
                mode="multiple"
                allowClear={true}
                style={{ width: 200 }}
                maxTagCount={"responsive"}
                options={[
                  { value: "tg", label: "Telegram" },
                  { value: "vk", label: "ВКонтакте" },
                  { value: "ok", label: "Одноклассники" },
                  { value: "fb", label: "Facebook" },
                  { value: "tw", label: "Twitter" },
                  //{ value: "dz", label: "Дзен" },
                  { value: "yt", label: "Youtube" },
                  { value: "ig", label: "Instagram" },
                  { value: "smi", label: "СМИ" },
                ]}
                placeholder="Тип источника"
                onChange={handleChangeSource}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    color={"#7856FE"}
                    overlayStyle={{ pointerEvents: "none" }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>Выбранные источники</span>
                  </Tooltip>
                )}
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    activeBorderColor: "#7856FE",
                  },
                },
              }}
            >
              <Select
                style={{ width: 195 }}
                allowClear={false}
                onChange={handleChangeTonal}
                options={[
                  { value: null, label: "Все" },
                  { value: 1, label: "Позитивные" },
                  { value: -1, label: "Негативные" },
                  { value: 0, label: "Нейтральные" },
                ]}
                placeholder="Тональность"
              />
            </ConfigProvider>
            <div className="left-border">
              <p className="grey">Наличие репоста</p>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#D8CCFB",
                    colorPrimaryHover: "#D8CCFB",
                    colorTextTertiary: "rgba(0, 0, 0, 0.2)",
                    colorTextQuaternary: "rgba(0, 0, 0, 0.1)",
                    fontSize: 10,
                  },
                  components: {
                    Switch: {
                      handleSize: 22,
                      trackPadding: -2,
                    },
                  },
                }}
              >
                <Switch value={switchValue} onChange={onChangeRepost} />
              </ConfigProvider>
            </div>
          </div>

          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>*/}
        <div className="feed-posts">
          <div className="flex">
            <div className="grey">Всего 4 отчета</div>
            {/*<p onClick={onClear} className="flex clear">
              Очистить фильтры <CloseOutlined className="info" />
      </p>*/}
          </div>
          {/*          <div className="grid6">
            <div
              className="violet-block"
              onClick={() => setCreateModalOpen(true)}
            >
              <a>
                <IconFileAdd />
                <div>Сформировать отчет</div>
              </a>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={morningReport}>
                <div className="inline-grid">
                  <IconFileExcel />
                  <span>Утренний отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={postListReport}>
                <div className="inline-grid">
                  <IconFileExcel />
                  <span>Сводка постов</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={topSourcesReport}>
                <div className="inline-grid">
                  <IconFileExcel />
                  <span>Топ источников</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={commentsReport}>
                <div className="inline-grid">
                  <IconFileExcel />
                  <span>Сводка комментариев</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={dailyReport}>
                <div className="inline-grid">
                  <IconFileWord />
                  <span>Ежедневный отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={weeklyReport}>
                <div className="inline-grid">
                  <IconFileWord />
                  <span>Еженедельный отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
          </div>*/}
          <div className="grid6">
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={dayReport}>
                <div className="inline-grid">
                  <IconFileWord />
                  <span>Ежедневный отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={weekReport}>
                <div className="inline-grid">
                  <IconFileWord />
                  <span>Еженедельный отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div
              className="gray-block"
              onClick={() => setReportModalOpen(true)}
            >
              <Link to={monthReport}>
                <div className="inline-grid">
                  <IconFileWord />
                  <span>Ежемесячный отчет</span>
                  <div>Скачать</div>
                </div>
              </Link>
            </div>
            <div className="gray-block btn-cursor" onClick={() => setModalOpen(true)}>
              <div className="inline-grid">
                <IconFileWord />
                <span>Отчет за период</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Формирование отчета"}
        open={reportModalOpen}
        onCancel={() => setReportModalOpen(false)}
        onOk={() => setReportModalOpen(false)}
        //cancelText="Отменить"
        okText="OK"
        footer={
          <Button key="back" onClick={() => setReportModalOpen(false)}>
            OK
          </Button>
        }
        maskClosable={false}
      >
        <div>
          Идёт формирование отчёта. Это может занять несколько минут. Вы можете
          продолжить работать в программе, нажав кнопку "ОК". Загрузка
          продолжится в фоновом режиме, отчёт сохранится в папку "Загрузки" на
          вашем компьютере.
        </div>
      </Modal>
      <Modal
        title={"Отчет"}
        open={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        onOk={okModal}
        cancelText="Отменить"
        okText="Загрузить"
        maskClosable={false}
      >
        <div className="grey margin">Сформировать по</div>
        <Radio.Group
          options={optionsType}
          onChange={(value) => setTypeReport(value.target.value)}
          value={typeReport}
          optionType="button"
          className="custom-radio"
        />
        <div className="grey margin">Формат</div>
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                buttonSolidCheckedColor: "#7856FE",
                buttonSolidCheckedBg: "#7856FE",
                colorPrimary: "#7856FE",
                buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
              },
            },
          }}
        >
          <Radio.Group
            options={optionsFormat}
            onChange={(value) => setReportFileType(value.target.value)}
            value={reportFileType}
          />
        </ConfigProvider>
      </Modal>
      <Modal
        title={"Отчет за период"}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalOpen(false)}>
            Отмена
          </Button>,
          <Button
            key="link"
            href={periodReport}
            type="primary"
            onClick={() => {setModalOpen(false); setReportModalOpen(true)}}
            // onClick={handleOk}
          >
            Загрузить
          </Button>,
        ]}
        maskClosable={false}
      >
        <RangePicker
          format={"DD-MM-YYYY"}
          onChange={onPeriodChange}
          style={{ width: "278px" }}
          allowClear={false}
          locale={locale}
        />
      </Modal>
    </div>
  );
};

export default Reports;
