import { AnyAction } from '@reduxjs/toolkit'
import * as actionType from '../../redux/actions/types'

export interface AppState {
  isInitialize: boolean
  references: any
  referencesFilter: number[]
  selectSource: any[]
  threads: any[]
  thread: any
  isMobile: boolean
  menuIsOpen: boolean
  feedFilterIsOpen: boolean
}

const initialState: AppState = {
  isInitialize: true,
  references: {
    department: null,
    subject: null,
    committee: null,
    eventful: null
  },
  referencesFilter: [],
  selectSource: [],
  threads: [],
  thread: null,
  isMobile: window.innerWidth <= 768,
  menuIsOpen: false,
  feedFilterIsOpen: false
}

export const appReducer = (state = initialState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case actionType.SET_APP_IS_INITIALIZE: {
      return { ...state, isInitialize: payload }
    }
    case actionType.SET_APP_IS_MOBILE: {
      return { ...state, isMobile: payload }
    }
    case actionType.SHOW_MENU: {
      return { ...state, menuIsOpen: true }
    }
    case actionType.HIDE_MENU: {
      return { ...state, menuIsOpen: false }
    }
    case actionType.SET_THREAD: {
      if (payload) {
        const thread = {
          id: payload.id,
          retro: payload.retro
        }
        return { ...state, thread }
      } else {
        return { ...state }
      }
    }
    case actionType.SET_THREADS: {
      return { ...state, threads: payload }
    }
    case actionType.SET_REFERENCES: {
      return { ...state, references: payload }
    }
    case actionType.SET_REFERENCES_FILTER: {
      return { ...state, referencesFilter: payload }
    }
    case actionType.SET_SELECTED_REF_SOURCE: {
      return { ...state, selectSource: payload }
    }
    default:
      return state
  }
}
