import * as topojson from "topojson-client";
import { Mercator } from "@visx/geo";
import { useTooltip } from "@visx/tooltip";
import topology from "../scripts/russia-topo.json";
import { TooltipWithBounds } from "@visx/tooltip";
import { useEffect, useState } from "react";
import { localPoint } from "@visx/event";
import { useSelector } from "react-redux";
import { getThread } from "../redux/selectors";
import { summaryService } from "../api";
import {
  ArrowUpOutlined,
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";

// types
interface FeatureShape {
  type: "Feature";
  geometry: { coordinates: [number, number][][]; type: "Polygon" };
  properties: { region: string };
}

// utils
let tooltipTimer: any;

// data normalized
const { features: countryStates } = topojson.feature(
  // @ts-ignore: Unreachable code error
  topology,
  topology.objects["russia"]
) as unknown as {
  type: "FeatureCollection";
  features: FeatureShape[];
};

type ChartMarginProps = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

// pre-defined values
const defaultMargin: ChartMarginProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

// props
type ChartMapProps = {
  width?: number;
  height?: number;
  margin?: ChartMarginProps;
};

const ChartMap = ({ width = 1209, height = 440 }: ChartMapProps) => {
  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<FeatureShape>();

  const [open, setOpen] = useState<any>({ region: "Санкт-Петербург" });
  const [mapData, setMapData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [translateX, setTranslateX] = useState(450);
  const [translateY, setTranslateY] = useState(250);
  const [scale, setScale] = useState(0.08);

  const thread = useSelector(getThread);

  useEffect(() => {
    setLoading(true);
    if (thread && open) {
      let params = {
        thread_id: +thread.id,
        city: open?.region,
      };

      summaryService
        .loadMap(params)
        .then((resp) => {
          setMapData(resp);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, open]);

  const increaseMap = () => {
    if (scale < 0.5) {
      setScale(scale + 0.05);
    }
  };

  const decreaseMap = () => {
    if (scale > 0.1) {
      setScale(scale - 0.05);
    }
    if (scale < 0.5) {
      setTranslateX(450);
      setTranslateY(250);
    }
  };

  const downMap = () => {
    {
      setTranslateY(translateY - 100);
    }
  };

  const upMap = () => {
    {
      setTranslateY(translateY + 100);
    }
  };

  const rightMap = () => {
    {
      setTranslateX(translateX - 100);
    }
  };

  const leftMap = () => {
    {
      setTranslateX(translateX + 100);
    }
  };

  if (width < 100) return null;
  return (
    <div className="white-block-map">
      <svg
        width={width}
        height={height}
        style={{ touchAction: "none" }}
        className="map-source"
      >
        <Mercator<FeatureShape>
          data={countryStates}
          scale={(width + height) * scale}
          translate={[translateX, translateY]}
        >
          {(mercator) => {
            return (
              <g>
                {mercator.features.map(({ feature, path }, i) => {
                  return (
                    <g key={`map-feature-${i}`}>
                      <path
                        d={path || ""}
                        fill={
                          open?.region == feature.properties.region
                            ? "rgba(120, 86, 254, 0.5)"
                            : "#D8D8D8"
                        }
                        stroke={"#fff"}
                        strokeWidth={0.5}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setOpen(feature.properties);
                        }}
                        onMouseLeave={() => {
                          tooltipTimer = setTimeout(hideTooltip, 150);
                        }}
                        onMouseMove={(e) => {
                          if (tooltipTimer) clearTimeout(tooltipTimer);
                          const { x, y } = localPoint(e) || {
                            x: 0,
                            y: 0,
                          };
                          showTooltip({
                            tooltipData: feature,
                            tooltipLeft: x,
                            tooltipTop: y,
                          });
                        }}
                      />
                    </g>
                  );
                })}
              </g>
            );
          }}
        </Mercator>
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          data-is-open={tooltipOpen}
          offsetLeft={50}
        >
          {tooltipData.properties.region}
        </TooltipWithBounds>
      )}
      <div className="zoom">
        <PlusOutlined onClick={increaseMap} />
        <MinusOutlined onClick={decreaseMap} />
      </div>
      <div className="zoom zoom-height">
        <UpOutlined onClick={upMap} />
        <DownOutlined onClick={downMap} />
      </div>
      <div className="zoom-left" onClick={leftMap}>
        <LeftOutlined />
      </div>
      <div className="zoom-right" onClick={rightMap}>
        <RightOutlined />
      </div>
      {open && (
        <div className="map-block">
          <div className="title-small">{open?.region}</div>
          {loading ? (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          ) : (
            <div className="gray-block">
              <div className="flex-space">
                <div className="light">Подписчиков</div>
                {mapData?.users_city}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChartMap;
