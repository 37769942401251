import "../App.css";
import NumberOfMentions from "../components/NumberOfMentions";
import TonalStatistic from "../components/TonalStatistic";
import BestSources from "../components/BestSources";
import PublicTop from "../components/PubicTop";
import BestThemes from "../components/BestThemes";
import Metrics from "../components/Metrics";
import MediaIndex from "../components/MediaIndex";

function Main() {

  return (
    <div className="App">
      <h1>Главная</h1>
      <NumberOfMentions />
      <TonalStatistic />
      <MediaIndex/>
      <BestSources />
      <PublicTop />
      <BestThemes />
      <Metrics />
    </div>
  );
}

export default Main;
