import { LogoutOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { logoutAuth } from "../redux/actions";
import { useAppDispatch } from "../redux/store/hooks";

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(logoutAuth());
  };

  const items: MenuProps["items"] = [
    {
      label: "Выйти",
      key: "logout",
      icon: <LogoutOutlined />
    },
  ];

  return (
    <div className="logout-icon">
      <Dropdown menu={{ items, onClick }}>
        <a onClick={(e) => e.preventDefault()}>
          <img src="/Avatar.png" width={48} />
        </a>
      </Dropdown>
    </div>
  );
};

export default Logout;
