import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

type Props = {
  labelsArr: any;
  dataArr: any;
  summ?: number
};

const DoughnutChart: React.FC<Props> = ({ labelsArr, dataArr, summ }: Props) => {
  Chart.register(
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    ArcElement,
    ChartDataLabels
  );

  const data = {
    labels: labelsArr,
    datasets: [
      {
        label: `${summ ? "" : "Share of voice"}`,
        data: dataArr,
        backgroundColor: [
          "#7856FE",
          "#FEB0A4",
          "#FF7555",
          "#D03529",
          "#1B0C3E",
        ],
        borderColor: ["#7856FE", "#FEB0A4", "#FF7555", "#D03529", "#1B0C3E"],
        cutout: "75%",
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const drawTotals = (chart: any) => {
    var width = 250,
      height = 250,
      { ctx } = chart;

    ctx.restore();
    var fontSize = 32;
    ctx.font = fontSize + "px sans-serif";
    ctx.textBaseline = "middle";

    let text = `${summ ? summ : dataArr[0] + "%"}`,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  };

  const getOrCreateTooltip = (chart:any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };

  const externalTooltipHandler = (context:any) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b:any) => b.lines);
  
      const tableHead = document.createElement('thead');
  
      titleLines.forEach((title:any) => {
        const tr = document.createElement('tr');
  
        const th = document.createElement('th');
        const text = document.createTextNode(title);
  
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body:any, i:any) => {
        const colors = tooltip.labelColors[i];
  
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
  
        const td = document.createElement('td');
  
        const text = document.createTextNode(body);
  
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  return (
    <div className="doughnut-chart">
      <Doughnut
        data={data}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler
            }
          },
        }}
        plugins={[
          {
            id: "drawСircles",
            beforeDraw(chart) {
              drawTotals(chart);
            },
            afterUpdate(chart) {
              const arcs = chart.getDatasetMeta(0).data;
              arcs.forEach((arc: any) => {
                arc.round = {
                  x: (chart.chartArea.left + chart.chartArea.right) / 2,
                  y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                  radius: (arc.outerRadius + arc.innerRadius) / 2,
                  thickness: (arc.outerRadius - arc.innerRadius) / 2,
                  backgroundColor: arc.options.backgroundColor,
                };
              });
            },
            afterDraw: (chart) => {
              const { ctx } = chart;

              chart.getDatasetMeta(0).data.forEach((arc: any) => {
                const endAngle = Math.PI / 2 - arc.endAngle;

                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);

                ctx.fillStyle = arc.options.backgroundColor;
                ctx.beginPath();
                if (arc.circumference > 0) {
                  ctx.arc(
                    arc.round.radius * Math.sin(endAngle),
                    arc.round.radius * Math.cos(endAngle),
                    arc.round.thickness,
                    0,
                    2 * Math.PI
                  );
                } else {
                  ctx.arc(
                    arc.round.radius * Math.sin(endAngle),
                    arc.round.radius * Math.cos(endAngle),
                    0,
                    arc.startAngle,
                    Math.PI + arc.endAngle
                  );
                }
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              });
            },
          },
        ]}
      />
    </div>
  );
};

export default DoughnutChart;
