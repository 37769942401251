import "../App.css";
import ChartMap from "../components/ChartMap";
import PostHorizontal from "../components/PostHorizontal";
import ThemesHorizontal from "../components/ThemesHorizontal";
import SmiHorizontal from "../components/SmiHorizontal";

function Sources() {
  return (
    <div className="App">
      <h1>Источники</h1>
      <ChartMap />
      <div className="grid2 main-block">
        <PostHorizontal />
        <SmiHorizontal />
        <ThemesHorizontal />
      </div>
    </div>
  );
}

export default Sources;
