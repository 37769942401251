import { LoadPostsResponse } from '../../../domain/usecases/post-service'
import { FeedPostModel } from '../../../domain/models/feed-post-model'
import { serializeDate } from '../../../usecases/utils/serialize-date'

export const loadPostsMapper = (resp: any): LoadPostsResponse => {
  const posts:any = []

  resp.posts.forEach((item:any) => {
    const post: FeedPostModel = {
      id: item.id,
      trust: item.trust.trust,
      ownerId: item.owner_id,
      text: item.text,
      title: item.title,
      createdDate: serializeDate(item.created_date),
      isRemoved: false,
      isManual: item.is_manual !== '0',
      uri: item.uri,
      smiType: item.smi_type || null,
      attendance: item.attendance,
      supercoefficient: item.supercoefficient,
      friendly: item.friendly,
      author: {
        name: item.author,
        logo: item.author_logo,
        url: item.author_url
      },
      network: {
        id: item.network_id,
        name: item.network_name
      },
      statistics: {
        likes: item.likes,
        reposts: item.reposts,
        comments: item.comments,
        viewed: item.viewed
      },
      media: {
        images: item.images,
        audio: item.audio,
        video: item.video
      }
    }
    posts.push(post)
  })

  return {
    count: resp.count,
    reportLink: resp.csv_link,
    posts
  }
}
