import React from "react";

const IconChecked: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 21H5C3.895 21 3 20.105 3 19V10C3 8.895 3.895 8 5 8H14C15.105 8 16 8.895 16 10V19C16 20.105 15.105 21 14 21Z"
        stroke="#7856FE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.125 14.9092L8.709 16.4932L12.166 13.0352"
        stroke="#7856FE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8V5C7 3.895 7.895 3 9 3H19C20.105 3 21 3.895 21 5V15C21 16.105 20.105 17 19 17H16"
        stroke="#7856FE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconChecked;
