import React from 'react'
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import { App } from "./App";
import LogRocket from 'logrocket'
import { store } from './redux/store';

LogRocket.init('cmx8ge/mediaplan')

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)