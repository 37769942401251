import { MainTopicsPostModel } from "../../../domain/models/main-topics-post-model"
import { serializeDate } from "../../utils/serialize-date"


export const topicMapper = (resp: any): MainTopicsPostModel[] => {
  const posts:any = []

  resp.forEach((item:any) => {
    const post: MainTopicsPostModel = {
      id: item.id,
      trust: item.trust.trust,
      ownerId: item.owner_id,
      text: item.text,
      title: item.title,
      createdDate: serializeDate(item.created_date),
      isManual: item.is_manual !== '0',
      uri: item.uri,
      author: {
        name: item.author,
        logo: item.author_logo,
        url: item.author_url
      },
      network: {
        id: item.network_id,
        name: item.network_name
      },
      statistics: {
        likes: item.likes,
        reposts: item.reposts,
        comments: item.comments,
        viewed: item.viewed
      },
      media: {
        images: item.images,
        audio: item.audio,
        video: item.video
      },
      attendance: item.attendance,
      supercoefficient: item.supercoefficient,
      reference_item_id: item.reference_item_id
    }
    posts.push(post)
  })

  return [...posts]
}
