import React from "react";

const IconYoutube: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 152 152" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Color">
          <g id="_02.YouTube" data-name="02.YouTube">
            <circle id="Background" cx="76" cy="76" fill="#f00" r="76"></circle>
            <path
              id="Icon"
              d="m100.87 47.41h-49.74a15.13 15.13 0 0 0 -15.13 15.14v26.9a15.13 15.13 0 0 0 15.13 15.14h49.74a15.13 15.13 0 0 0 15.13-15.14v-26.9a15.13 15.13 0 0 0 -15.13-15.14zm-35.41 40.85v-24.52l21.08 12.26z"
              fill="#fff"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconYoutube;
