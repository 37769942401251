import { AnyAction } from 'redux'
import * as actionType from './types'
import { closeConfirmDeletePostModal, setConfirmDeletePostIsLoading } from './delete-post.actions'
import { LoadPostsParams } from '../../api/domain/usecases/post-service'
import { postService } from '../../api'

export const setPostList = (list:any): AnyAction => ({ type: actionType.SET_POST_LIST, payload: list })
export const setPostCount = (count:any): AnyAction => ({ type: actionType.SET_POST_COUNT, payload: count })
export const setPostPageNumber = (pageNumber:any): AnyAction => ({ type: actionType.SET_POST_PAGE_NUMBER, payload: pageNumber })
export const setPostReportLink = (link:any): AnyAction => ({ type: actionType.SET_POST_REPORT_LINK, payload: link })
export const setPostPageSize = (size:number): AnyAction => ({ type: actionType.SET_POST_PAGE_SIZE, payload: size })


export const fetchPosts = () => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_POSTS_START })
  const { app, feedFilter, posts } = getState()
  let trustoption = feedFilter.tonal !== "" ? feedFilter.tonal : undefined;
  const params: LoadPostsParams = {
    id: app.thread.id,
    startPostIndex: posts.pageSize * (posts.pageNumber - 1),
    from: feedFilter.from,
    to: feedFilter.to,
    filter: {
      network_id: app.selectSource.length > 0 ? app.selectSource.slice(-1) : feedFilter.networks.length > 0 ? feedFilter.networks : feedFilter.network_id,
      referenceFilter: app.referencesFilter,
      repostoption: feedFilter.repostoption,
      trustoption,
      //friendly: feedFilter.friendly !=null ? feedFilter.friendly == 1 ? [1,2] : [0] : [],
      //profile_id: app.selectSource.slice(0,-1),
      //profile_id: !feedFilter.profile_id ? app.selectSource.slice(0,-1) : feedFilter.profile_id.slice(0,-1),
    },
    sort: {
      order: feedFilter.order,
      type: feedFilter.type,
      name: feedFilter.name
    },
  }

  postService.loadPosts(params)
    .then(resp => {
      dispatch(setPostList(resp.posts))
      dispatch(setPostCount(resp.count))
      dispatch(setPostReportLink(resp.reportLink))
      dispatch({ type: actionType.FETCH_POSTS_SUCCESS })
      window.scrollTo(0, 0)
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POSTS_FAIL, payload: error.message })
    })
}

export const fetchPostAttendance = (id: string, ownerId: string, networkId: string) => async (dispatch:any) => {
  postService.loadPostAttendance(id, ownerId, networkId)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_POST_ATTENDANCE_SUCCESS,
        payload: {
          id,
          attendance: resp.attendance
        }
      })
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POST_ATTENDANCE_FAIL, payload: error.message })
    })
}

export const changePostTrust = (id: string, ownerId: string, networkId: string, postIsManual: boolean, trust: number) => async (dispatch:any) => {
  const params = {
    id: id,
    owner_id: ownerId,
    network_id: networkId,
  }

  const params3 = {
    post_id: id,
    owner_id: ownerId,
    network_id: networkId,
    trust: trust,
    is_manual: postIsManual
  }
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.getPostSiblings(params)
    .then(resp => {
      if (resp?.posts?.length > 0)
      resp?.posts?.map((item:any) => {
        const params2 = {
          post_id: item.id,
          owner_id: item.owner_id,
          network_id: item.network_id,
          trust: trust,
          is_manual: postIsManual
        };
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          postService.setPostTrust(params2)
            .then(resp => {
              dispatch({
                type: actionType.FETCH_POST_CHANGE_TRUST_SUCCESS,
                payload: {
                  id: item.id,
                  trust
                }
              })
            })
            .catch(error => {
              dispatch({ type: actionType.FETCH_POST_CHANGE_TRUST_FAIL, payload: error.message })
            })
        });
    else
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          postService.setPostTrust(params3)
            .then(resp => {
              dispatch({
                type: actionType.FETCH_POST_CHANGE_TRUST_SUCCESS,
                payload: {
                  id,
                  trust
                }
              })
            })
            .catch(error => {
              dispatch({ type: actionType.FETCH_POST_CHANGE_TRUST_FAIL, payload: error.message })
            })
    })
    .catch(error => {
      //dispatch({ type: actionType.FETCH_POST_CHANGE_TRUST_FAIL, payload: error.message })
      postService.setPostTrust(params3)
      .then(resp => {
        dispatch({
          type: actionType.FETCH_POST_CHANGE_TRUST_SUCCESS,
          payload: {
            id,
            trust
          }
        })
      })
    })
}

export const addStopSource = (ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_SOURCE_REMOVE_START })
  const { app } = getState()
  const params = {
    thread_id: parseInt(app.thread.id),
    profile_id: ownerId,
    network_id: networkId,
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.addStopSource(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_SOURCE_REMOVE_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_SOURCE_REMOVE_FAIL, payload: error.message })
    })
}

export const removeStopSource = (ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_SOURCE_REMOVE_START })
  const { app } = getState()
  const params = {
    thread_id: parseInt(app.thread.id),
    profile_id: ownerId,
    network_id: networkId,
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.removeStopSource(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_SOURCE_REMOVE_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_SOURCE_REMOVE_FAIL, payload: error.message })
    })
}

export const removePost = (id: string, ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_POST_REMOVE_START })
  dispatch(setConfirmDeletePostIsLoading(true))
  const { app } = getState()
  const params = {
    //thread_id: parseInt(app.thread.id),
    reference_item_id: parseInt(app.referencesFilter),
    post_id: id,
    owner_id: parseInt(ownerId),
    network_id: parseInt(networkId)
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.addRefStopPost(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_POST_REMOVE_SUCCESS,
        payload: {
          id,
          ownerId
        }
      })
      dispatch(setConfirmDeletePostIsLoading(false))
      dispatch(closeConfirmDeletePostModal())
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POST_REMOVE_FAIL, payload: error.message })
      dispatch(setConfirmDeletePostIsLoading(false))
      dispatch(closeConfirmDeletePostModal())
    })
}

export const removeThreadPost = (id: string, ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_POST_REMOVE_START })
  dispatch(setConfirmDeletePostIsLoading(true))
  const { app } = getState()
  const params = {
    thread_id: app.thread.id,
    post_id: id,
    owner_id: ownerId,
    network_id: networkId
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.addStopPost(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_POST_REMOVE_SUCCESS,
        payload: {
          id,
          ownerId
        }
      })
      dispatch(setConfirmDeletePostIsLoading(false))
      dispatch(closeConfirmDeletePostModal())
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POST_REMOVE_FAIL, payload: error.message })
      dispatch(setConfirmDeletePostIsLoading(false))
      dispatch(closeConfirmDeletePostModal())
    })
}

export const backThreadRemovedPost = (id: string, ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_POST_CANCEL_REMOVE_START })
  const { app } = getState()
  const params = {
    thread_id: parseInt(Math.max(...app.threads.map((it:any)=> it.id)).toString()),
    post_id: id,
    owner_id: parseInt(ownerId),
    network_id: parseInt(networkId)
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.removeStopPost(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_POST_CANCEL_REMOVE_SUCCESS,
        payload: {
          id,
          ownerId
        }
      })
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POST_CANCEL_REMOVE_FAIL, payload: error.message })
    })
}

export const backRemovedPost = (id: string, ownerId: string, networkId: string) => async (dispatch:any, getState:any) => {
  dispatch({ type: actionType.FETCH_POST_CANCEL_REMOVE_START })
  const { app } = getState()
  const params = {
    //thread_id: parseInt(app.thread.id),
    reference_item_id: parseInt(app.referencesFilter),
    post_id: id,
    owner_id: parseInt(ownerId),
    network_id: parseInt(networkId)
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  postService.removeRefStopPost(params)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_POST_CANCEL_REMOVE_SUCCESS,
        payload: {
          id,
          ownerId
        }
      })
    })
    .catch(error => {
      dispatch({ type: actionType.FETCH_POST_CANCEL_REMOVE_FAIL, payload: error.message })
    })
}
export const setRefStopPost = (stopPostList:any): AnyAction => ({ type: actionType.SET_STOP_POST_LIST, payload: stopPostList })

export const changePostPage = (pageNumber:any) => (dispatch:any) => {
  dispatch(setPostPageNumber(pageNumber))
  //dispatch(fetchPosts())
}
