import React from "react";

const IconStars: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81787 2.81813V2.81813C1.06037 4.57563 1.06037 7.42463 2.81787 9.18213V9.18213C4.57537 10.9396 7.42437 10.9396 9.18187 9.18213V9.18213C10.9394 7.42463 10.9394 4.57563 9.18187 2.81813V2.81813C7.42437 1.06062 4.57537 1.06062 2.81787 2.81813Z"
        stroke="#7856FE"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.5L4.5 7.5"
        stroke="#7856FE"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 6.75V4.5H5.25"
        stroke="#7856FE"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconStars;
