import { ReferenceListItemModel } from "../../../domain/models/reference-list-item"
import { ReferencesModel } from "../../../domain/models/references-model"


const REFERENCES_NAMES = ['department', 'subject', 'committee', 'eventful']

const itemMapper = (item: {id: number, keyword: string, reference_id: number, group_id: number}): ReferenceListItemModel => {
  return {
    id: item.id,
    keyword: item.keyword,
    referenceId: item.reference_id,
    groupId: item.group_id
  }
}

export const referencesMapper = (resp: any): ReferencesModel => {
  const result:any = {
    committee: null,
    department: null,
    eventful: null,
    subject: null
  }

  resp.forEach((reference:any, index:any) => {
    const referenceName = REFERENCES_NAMES[index]
    result[referenceName] = {
      ...reference,
      items: reference.items.map((item:any) => itemMapper(item))
    }
  })

  return result
}
