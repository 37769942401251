import React from "react";

const IconStars: React.FC = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.46 6.99999L18.25 5.24999L20 4.45999C20.39 4.27999 20.39 3.72999 20 3.54999L18.25 2.75999L17.46 0.999989C17.28 0.609989 16.73 0.609989 16.55 0.999989L15.76 2.74999L14 3.53999C13.61 3.71999 13.61 4.26999 14 4.44999L15.75 5.23999L16.54 6.99999C16.72 7.38999 17.28 7.38999 17.46 6.99999ZM9.50004 8.49999L7.91004 4.99999C7.56004 4.21999 6.44004 4.21999 6.09004 4.99999L4.50004 8.49999L1.00004 10.09C0.220039 10.45 0.220039 11.56 1.00004 11.91L4.50004 13.5L6.09004 17C6.45004 17.78 7.56004 17.78 7.91004 17L9.50004 13.5L13 11.91C13.78 11.55 13.78 10.44 13 10.09L9.50004 8.49999ZM16.54 15L15.75 16.75L14 17.54C13.61 17.72 13.61 18.27 14 18.45L15.75 19.24L16.54 21C16.72 21.39 17.27 21.39 17.45 21L18.24 19.25L20 18.46C20.39 18.28 20.39 17.73 20 17.55L18.25 16.76L17.46 15C17.28 14.61 16.72 14.61 16.54 15Z"
        fill="white"
      />
    </svg>
  );
};

export default IconStars;
