export const findSource = (text) => {
  switch (text) {
    case "vk":
      return "ВКонтакте";
      break;
    case "dz":
      return "Дзен";
      break;
    case "ok":
      return "Одноклассники";
      break;
    case "tg":
      return "Телеграм";
      break;
    case "ig":
      return "Instagram";
      break;
    case "tw":
      return "Twitter";
      break;
    case "fb":
      return "Facebook";
      break;
    case "yt":
      return "YouTube";
      break;
    case "gs":
      return "СМИ";
      break;
    default:
      return text;
  }
};

export const calcChange = (now, previous) => {
  return now != 0 && previous != 0
    ? ((now * 100) / previous - 100).toFixed(2)
    : now != 0 && previous == 0
    ? "100.00"
    : now == 0 && previous != 0
    ? "-100.00"
    : "0.00";
};
