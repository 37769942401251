import { HttpPostClient, HttpStatusCode } from '../../protocols/http'
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors'
import { UserService } from '../../domain/usecases/user-service'

export class RemoteUserService implements UserService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}


  async check (): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/users/get',
      body: {}
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

}
