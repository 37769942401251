import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FallOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { summaryService } from "../api";
import { getThread } from "../redux/selectors";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { calcChange } from "../scripts/utils";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";
import IconStars from "./icons/Stars";

const NumberOfMentions: React.FC = () => {
  const [value, setValue] = useState("today");
  const [labelsArr, setLabelsArr] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  const { RangePicker } = DatePicker;
  const thread = useSelector(getThread);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const [loadingMentions, setLoadingMentions] = useState(true);
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [competitors, setCompetitors] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [totalMentions, setTotalMentions] = useState(0);
  const [client, setClient] = useState("");
  const [previousMentions, setPreviousMentions] = useState(0);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
      };
      summaryService
        .loadShareOfVoiceInfo(params)
        .then((resp) => {
          const lables: any = [];
          lables.push(resp.client.title);
          resp.competitors.items.forEach(
            (it: any) => it.share_of_voice != 0 && lables.push(it.title)
          );
          setLabelsArr(lables);
          const data: any = [];
          data.push(resp.client.share_of_voice);
          resp.competitors.items.forEach(
            (it: any) => it.share_of_voice > 1 && data.push(it.share_of_voice)
          );
          setDataArr(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoadingMentions(true);
      summaryService
        .loadMediaIndexInfo(params)
        .then((resp) => {
          setCompetitors(resp.competitors.items);
          setTotalMentions(resp.client.mentions);
          setClient(resp.client.title);
          let calc = calcChange(
            resp.client.mentions,
            resp.client.previous.mentions
          );

          setPreviousMentions(+calc);
          setLoadingMentions(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString]);

  useEffect(() => {
    setLoadingGraph(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
      };
      summaryService
        .loadClientGraph(params)
        .then((resp) => {
          setGraphData(resp);
          setLoadingGraph(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const getDate = (date: string) => {
    let dateSeparator = "-";

    if (date.includes(dateSeparator)) {
      let dateArr = date.split("-");
      let month = parseInt(dateArr[1]) - 1;

      return dateArr[2].slice(0, 2) + " " + months[month];
    }

    return date;
  };

  return (
    <div className="white-block">
      <div className="grid2-1">
        <div>
          <div className="flex-space">
            <div>
              <p className="block-title">
                Количество упоминаний компании {client}
                <InfoCircleOutlined className="info" onClick={showDrawer} />
              </p>
            </div>
            <div className="flex">
              <ConfigProvider
                theme={{
                  components: {
                    Radio: {
                      buttonSolidCheckedColor: "#7856FE",
                      buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                      colorPrimary: "rgba(0, 0, 0, 0.1)",
                      buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                    },
                  },
                }}
              >
                <Radio.Group
                  options={options}
                  onChange={onChange}
                  value={value}
                  optionType="button"
                  buttonStyle="solid"
                />
              </ConfigProvider>

              {/*<ConfigProvider
                theme={{
                  components: {
                    Select: {
                      activeBorderColor: "#7856FE",
                    },
                  },
                }}
              >
                <Select
                  defaultValue="all"
                  style={{ width: 120 }}
                  allowClear={false}
                  options={[
                    { value: "all", label: "Все" },
                    { value: "smi", label: "СМИ" },
                    { value: "soc", label: "Соцсети" },
                  ]}
                  placeholder="select it"
                />
                </ConfigProvider>*/}
              {value == "period" && (
                <RangePicker
                  format={"DD-MM-YYYY"}
                  onChange={onPeriodChange}
                  style={{ width: "278px" }}
                  className="choose-period"
                  allowClear={false}
                  locale={locale}
                />
              )}
            </div>
          </div>

          {loadingMentions ? (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          ) : (
            <div>
              <div className="flex margin-bottom">
                <div className="title-big">
                  {totalMentions?.toLocaleString()}
                </div>
                <Tooltip
                  color={"#7856FE"}
                  title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
                >
                  {previousMentions > 0 ? (
                    <div className="green green-bg">
                      <RiseOutlined /> +{previousMentions?.toFixed(2)}%
                    </div>
                  ) : (
                    <div className="red red-bg">
                      <FallOutlined /> {previousMentions?.toFixed(2)}%
                    </div>
                  )}
                </Tooltip>
              </div>
              <p className="block-title">Конкуренты</p>
              <div className="grid4 margin-bottom">
                {competitors.map((competitor: any, id) => (
                  <div className="flex" key={id}>
                    <div className="small-img-frame">
                      <img src="/Avatar.png" alt="" />
                    </div>
                    <div>
                      <p className="grey">{competitor?.title}</p>
                      <div className="flex-baseline">
                        <div className="title-small">
                          {competitor?.mentions}
                        </div>
                        <Tooltip
                          color={"#7856FE"}
                          title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
                        >
                          <div
                            className={
                              competitor?.previous.mentions >=
                              competitor?.mentions
                                ? "red"
                                : "green"
                            }
                          >
                            {calcChange(
                              competitor?.mentions,
                              competitor?.previous.mentions
                            )}
                            %
                            {competitor?.previous.mentions >=
                            competitor?.mentions ? (
                              <ArrowDownOutlined />
                            ) : (
                              <ArrowUpOutlined />
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {loadingGraph ? (
            <div className="feed-posts-loader" style={{ height: "300px" }}>
              <LoadingOutlined />
            </div>
          ) : (
            <BarChart graphData={graphData} />
          )}
        </div>
        <div>
          <div className="gradient-block">
            <p className="block-title">Совет платформы</p>
            <p>
              Добавьте ещё 2 публикации для достижения среднего рыночного
              показателя
            </p>
            <div className="stars-icon">
              <IconStars />
            </div>
          </div>
          <div className="gray-block">
            <div className="flex-space">
              <div>
                <p className="block-title">
                  Share of voice{" "}
                  <InfoCircleOutlined className="info" onClick={showDrawer} />
                </p>
                <p className="grey light">
                  Результаты за {getDate(dateString[0])}{" "}
                  {getDate(dateString[1]) != getDate(dateString[0]) &&
                    "по " + getDate(dateString[1])}
                </p>
              </div>
              {/*<div className="border-grey">
                <RightOutlined />
              </div>*/}
            </div>
            {loading ? (
              <div className="feed-posts-loader" style={{ height: "300px" }}>
                <LoadingOutlined />
              </div>
            ) : (
              <DoughnutChart labelsArr={labelsArr} dataArr={dataArr} />
            )}
            <div className="legend-string">
              <div className="legend-square"></div>
              <p>Мои данные</p>
            </div>
          </div>
        </div>
      </div>
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График количества упоминаний иллюстрирует количество упоминаний вашего
          бренда и конкурентов в различных социальных сетях за выбранный период
          времени
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Цветовая кодировка: Разные цвета на графике обозначают ваш бренд и
            конкурентов. Это позволяет легко сравнивать уровень упоминаний.
          </li>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики упоминаний.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default NumberOfMentions;
