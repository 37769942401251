import "../App.css";
import AudienceDonuts from "../components/AudienceDonuts";
import Authors from "../components/Authors";
import ChartMap from "../components/ChartMap";

function Audience() {

  return (
    <div className="App">
      <h1>Аудитория</h1>
      <ChartMap />
      <AudienceDonuts />
      <Authors />
    </div>
  );
}

export default Audience;
