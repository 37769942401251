import React from 'react'

const IconVk: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#4D76A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.69785 11.5087H8.32575C8.32575 11.5087 8.51542 11.4879 8.61225 11.3835C8.70138 11.2877 8.69853 11.1077 8.69853 11.1077C8.69853 11.1077 8.68626 10.2652 9.07729 10.1411C9.46276 10.0189 9.95775 10.9554 10.4824 11.3155C10.8791 11.588 11.1806 11.5282 11.1806 11.5282L12.5834 11.5087C12.5834 11.5087 13.3173 11.4635 12.9693 10.8865C12.9408 10.8393 12.7667 10.4597 11.9263 9.67963C11.0467 8.8632 11.1645 8.99525 12.2241 7.58301C12.8694 6.72294 13.1273 6.19786 13.0468 5.97297C12.9699 5.75877 12.4953 5.81539 12.4953 5.81539L10.9158 5.82523C10.9158 5.82523 10.7987 5.80926 10.7119 5.86117C10.627 5.91208 10.5724 6.03073 10.5724 6.03073C10.5724 6.03073 10.3224 6.69628 9.98898 7.26229C9.28564 8.45662 9.00442 8.51966 8.88948 8.4455C8.62209 8.27266 8.68883 7.75114 8.68883 7.38065C8.68883 6.22325 8.86438 5.74066 8.347 5.61574C8.1753 5.57424 8.04895 5.54686 7.60986 5.54244C7.04628 5.53659 6.56925 5.54415 6.29916 5.67649C6.11947 5.76448 5.98085 5.96056 6.06528 5.97183C6.16967 5.98581 6.40611 6.03558 6.53146 6.20628C6.69332 6.42646 6.68762 6.92103 6.68762 6.92103C6.68762 6.92103 6.7806 8.2835 6.47043 8.45277C6.25751 8.56886 5.96545 8.33184 5.33841 7.24831C5.01711 6.69328 4.77454 6.07978 4.77454 6.07978C4.77454 6.07978 4.72776 5.96513 4.64434 5.90381C4.54309 5.82951 4.40162 5.80583 4.40162 5.80583L2.90067 5.81567C2.90067 5.81567 2.67535 5.82195 2.59264 5.91992C2.51906 6.00705 2.5868 6.18731 2.5868 6.18731C2.5868 6.18731 3.76188 8.9365 5.09241 10.3219C6.31242 11.5921 7.69785 11.5087 7.69785 11.5087Z" fill="white"/>
    </svg>
  )
}

export default IconVk
