import React from "react";

const IconVisibility: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7684 8.23361C12.7442 9.20945 12.7442 10.7936 11.7684 11.7711C10.7926 12.7469 9.2084 12.7469 8.2309 11.7711C7.25507 10.7953 7.25507 9.21111 8.2309 8.23361C9.2084 7.25611 10.7917 7.25611 11.7684 8.23361"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 9.99984C2.5 9.45067 2.62667 8.90734 2.87167 8.4065V8.4065C4.13417 5.82567 6.92417 4.1665 10 4.1665C13.0758 4.1665 15.8658 5.82567 17.1283 8.4065V8.4065C17.3733 8.90734 17.5 9.45067 17.5 9.99984C17.5 10.549 17.3733 11.0923 17.1283 11.5932V11.5932C15.8658 14.174 13.0758 15.8332 10 15.8332C6.92417 15.8332 4.13417 14.174 2.87167 11.5932V11.5932C2.62667 11.0923 2.5 10.549 2.5 9.99984Z"
        stroke="#1D1929"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconVisibility;
