import { AnyAction } from 'redux'
import { FeedPostModel } from '../../api/domain/models/feed-post-model'
import * as actionType from '../actions/types'


export interface PostsState {
  stop_posts: FeedPostModel[]
  list: FeedPostModel[]
  count: number
  pageNumber: number
  reportLink: string
  pageSize: number
  isLoading: boolean
  errorMsg: string
}

const initialState: PostsState = {
  list: [],
  count: 0,
  pageNumber: 1,
  reportLink: '',
  pageSize: 20,
  isLoading: true,
  errorMsg: '',
  stop_posts: []
}

export const postsReducer = (state = initialState, { type, payload }: AnyAction): PostsState => {
  switch (type) {
    case actionType.FETCH_POSTS_START: {
      return { ...state, isLoading: true }
    }
    case actionType.FETCH_POSTS_SUCCESS: {
      return { ...state, isLoading: false }
    }
    case actionType.FETCH_POSTS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload }
    }
    case actionType.FETCH_POST_ATTENDANCE_SUCCESS: {
      const list = [...state.list]
      const post:any = list.find(item => item.id === payload.id)
      post.attendance = payload.attendance
      return { ...state, list }
    }
    case actionType.FETCH_POST_CHANGE_TRUST_SUCCESS: {
      const list = [...state.list]
      const post = list.find(item => item.id === payload.id)
      if (post) {
        post.trust = payload.trust
      }
      return { ...state, list }
    }
    case actionType.FETCH_POST_REMOVE_SUCCESS: {
      const list = [...state.list]
      const post = list.find(item => item.id === payload.id && item.ownerId === payload.ownerId)
      if (post) {
        post.isRemoved = true
      }
      return { ...state, list }
    }
    case actionType.FETCH_POST_CANCEL_REMOVE_SUCCESS: {
      const list = [...state.list]
      const post = list.find(item => item.id === payload.id && item.ownerId === payload.ownerId)
      if (post) {
        post.isRemoved = false
      }
      return { ...state, list }
    }
    case actionType.SET_POST_LIST: {
      return { ...state, list: payload }
    }
    case actionType.SET_STOP_POST_LIST: {
      return { ...state, stop_posts: payload }
    }
    case actionType.SET_POST_COUNT: {
      return { ...state, count: payload }
    }
    case actionType.SET_POST_PAGE_NUMBER: {
      return { ...state, pageNumber: payload }
    }
    case actionType.SET_POST_REPORT_LINK: {
      return { ...state, reportLink: payload }
    }
    case actionType.SET_POST_PAGE_SIZE: {
      return { ...state, pageSize: payload }
    }
    default:
      return state
  }
}
