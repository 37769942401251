import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThread } from "../redux/selectors";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "antd/es/date-picker/locale/ru_RU";
import { summaryService } from "../api";
import { calcChange } from "../scripts/utils";

type Props = {
  title: any;
};

const Coverage: any = ({ title }: Props) => {
  const [value, setValue] = useState("today");
  const [mainNumber, setMainNumber] = useState(250552);
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>({
    activity: 0,
    attendance: 0,
    previous: { attendance: 0, viewed: 0, activity: 0 },
    viewed: 0,
  });
  const [network, setNetwork] = useState(1);
  const [loading, setLoading] = useState(true);
  const feedFilter = useSelector(getFeedFilter);
  const thread = useSelector(getThread);
  const start = new Date();
  start.setDate(start.getDate() - 29);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        network_id: network,
      };

      summaryService
        .loadClientStats(params)
        .then((resp) => {
          setData(resp);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, network]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "1м", value: "month" },
  ];

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const handleChangeNetwork = (value: number) => {
    setNetwork(value);
  };

  return (
    <div className="white-block-small">
      <div>
        <p className="block-title">
          {title == "viewed"
            ? "Просмотры"
            : title == "activity"
            ? "Активность"
            : "Приблизительный охват"}
          <InfoCircleOutlined className="info" onClick={showDrawer} />
        </p>
        <div className="flex">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonSolidCheckedColor: "#7856FE",
                  buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                  colorPrimary: "rgba(0, 0, 0, 0.1)",
                  buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                },
              },
            }}
          >
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </ConfigProvider>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  activeBorderColor: "#7856FE",
                },
              },
            }}
          >
            <Select
              value={network}
              onChange={handleChangeNetwork}
              style={{ width: 120 }}
              allowClear={false}
              options={[
                { value: 1, label: "ВКонтакте" },
                { value: 5, label: "Telegram" },
                { value: 10, label: "Одноклассники" },
                { value: 3, label: "Facebook" },
                { value: 2, label: "Twitter" },
                { value: 8, label: "Youtube" },
                { value: 7, label: "Instagram" },
                { value: 4, label: "СМИ" },
              ]}
            />
          </ConfigProvider>

          {value == "period" && (
            <RangePicker
              format={"DD-MM-YYYY"}
              onChange={onPeriodChange}
              style={{ width: "278px" }}
              className="choose-period"
              allowClear={false}
              locale={locale}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader" style={{ height: "300px" }}>
          <LoadingOutlined />
        </div>
      ) : (
        <div className="text-center">
          <Tooltip
            color={"#7856FE"}
            title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
          >
            <p
              className={data[title] <= data.previous[title] ? "red" : "green"}
            >
              {calcChange(data[title], data.previous[title])}%
              {data[title] <= data.previous[title] ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowUpOutlined />
              )}
            </p>
          </Tooltip>
          <div
            className={
              data[title] > 100000000
                ? "title-big margin-bottom"
                : "title-large margin-bottom"
            }
          >
            {data[title]?.toLocaleString()}
          </div>
          <Button onClick={showDrawer}>Подробнее</Button>
        </div>
      )}
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График количества упоминаний иллюстрирует количество упоминаний вашего
          бренда и конкурентов в различных социальных сетях за выбранный период
          времени
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Цветовая кодировка: Разные цвета на графике обозначают ваш бренд и
            конкурентов. Это позволяет легко сравнивать уровень упоминаний.
          </li>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики упоминаний.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default Coverage;
