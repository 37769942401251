import { AnyAction } from 'redux'
import * as actionType from '../actions/types'

export interface FeedFilterState {
  from: string
  to: string
  smi: boolean
  social: boolean
  vk: boolean
  tw: boolean
  fb: boolean
  ig: boolean
  tg: boolean
  yt: boolean
  ok: boolean
  networks: number[]
  network_id: number[]
  isActive: boolean
  isOpen: boolean
  tonal: string
  friendly: string
  sort: any
  profile_id: string
  repostoption: string
}

const nowDate = new Date().toJSON().split('T')[0]

const initialState: FeedFilterState = {
  from: nowDate + " 00:00:00",
  to: nowDate + " 23:59:59",
  smi: true,
  social: false,
  vk: true,
  tw: true,
  fb: true,
  ig: true,
  tg: true,
  yt: true,
  ok: true,
  networks: [1, 2, 3, 4, 5, 7, 8, 10],
  network_id: [1, 2, 3, 4, 5, 7, 8, 10],
  isActive: false,
  isOpen: false,
  tonal: '',
  friendly: '',
  sort: null,
  profile_id: '',
  repostoption : 'whatever'
}

export const feedFilterReducer = (state = initialState, { type, payload }: AnyAction): FeedFilterState => {
  switch (type) {
    case actionType.SET_FEED_FILTER: {
      let networks = []
      let order  = 'desc'
      let type = 'date'
      if (payload.vk) {
        networks.push(1)
      }
      if (payload.tw) {
        networks.push(2)
      }
      if (payload.fb) {
        networks.push(3)
      }
      if (payload.smi) {
        networks.push(4)
      }
      if (payload.ig) {
        networks.push(7)
      }
      if (payload.tg) {
        networks.push(5)
      }
      if (payload.yt) {
        networks.push(8)
      }
      if (payload.ok) {
        networks.push(10)
      }
      if (payload.social) {
        networks=[]
      }
      if (!payload.vk && !payload.tg && !payload.tw && !payload.yt) {
        networks.push(0)
      }

      if (payload.sorting == 'reitAsc') {
        type = 'rating'
        order = 'asc'
      }
      if (payload.sorting == 'reitDesc') {
        type = 'rating'
        order = 'desc'
      }
      if (payload.sorting == 'dateAsc') {
        type = 'date'
        order = 'asc'
      }
      if (payload.sorting == 'dateDesc') {
        type = 'date'
        order = 'desc'
      }
      if (payload.sorting == 'likeAsc') {
        type = 'likes'
        order = 'asc'
      }
      if (payload.sorting == 'likeDesc') {
        type = 'likes'
        order = 'desc'
      }
      if (payload.sorting == 'repostAsc') {
        type = 'reposts'
        order = 'asc'
      }
      if (payload.sorting == 'repostDesc') {
        type = 'reposts'
        order = 'desc'
      }
      if (payload.sorting == 'viewAsc') {
        type = 'viewed'
        order = 'asc'
      }
      if (payload.sorting == 'viewDesc') {
        type = 'viewed'
        order = 'desc'
      }
      if (payload.sorting == 'comAsc') {
        type = 'commentaries'
        order = 'asc'
      }
      if (payload.sorting == 'comDesc') {
        type = 'commentaries'
        order = 'desc'
      }
      if (payload.sorting == '') {
        type = 'date'
        order = 'desc'
      }
      const filter = {
        ...state,
        ...payload,
        networks,
        isActive: true
      }
     const sort = {
        type,
        order,
      }
      localStorage.setItem('feedFilter', JSON.stringify(filter))
      return { ...filter, ...sort }
    }
    case actionType.SHOW_FEED_FILTER: {
      return { ...state, isOpen: true }
    }
    case actionType.HIDE_FEED_FILTER: {
      return { ...state, isOpen: false }
    }
    case actionType.RESET_FEED_FILTER: {
      localStorage.removeItem('feedFilter')
      return { ...state, ...initialState }
    }
    case actionType.REMOVE_FEED_FILTER: {
      localStorage.removeItem('feedFilter')
      return { ...state }
    }
    default:
      return state
  }
}
